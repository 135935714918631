import Image from "next/image"
import Link from "next/link"
import { FC } from "react"

// interface Props {
//   name: string
//   imageUrl: string
//   job: string
//   handle: string
//   classType: string
//   showClassType: boolean
// }
interface Props {
  data: {
    name: string
    cover_image: string
    expertise: string
    tier: string | null
    id: number
  }
  showClassType: boolean
}

const CoachCard: FC<Props> = ({
  data: { name, cover_image, expertise, tier, id },
  showClassType,
}) => {
  const classType = Boolean(tier)
    ? "Personal Training & Classes"
    : "Classes Only"

  return (
    <div className="w-[361px]  group max-w-[calc(100vw-8px)]  rounded-xl overflow-hidden border border-gray-200 shadow-lg h-full">
      <div className="w-[361px]  relative h-[361px] overflow-hidden">
        <Image
          className="object-cover object-center transition-transform duration-150 ease-out group-hover:scale-110"
          src={cover_image!}
          alt={name!}
          fill
          loading="lazy"
        />
      </div>
      <div className="p-8 transition-all duration-150 ease-out">
        <h4 className="text-xl font-bold font-display">{name}</h4>
        <h5 className="text-xl my-2 truncate font-semibold text-[#77777a]">
          {expertise}
        </h5>
        {showClassType && (
          <p className="text-lg my-2  text-[#77777a]">{classType}</p>
        )}
        <div>
          <Link
            aria-label="View Profile of Coach"
            className="bg-[url('/icons/skewed-right-yellow.svg')] mt-4 transition-all duration-150 ease-out font-bold group-hover:bg-[url('/icons/skewed-right-black-coaches.svg')]  group-hover:text-brand bg-contain group-hover:hover:bg-[url('/icons/skewed-right-white-yellow-border-coaches.svg')] bg-no-repeat w-[240px] h-[45px] flex items-center justify-center"
            href={`/team-member/${id}`}
          >
            View Profile
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CoachCard
