import BsFillCaretLeftFill from "@/public/icons/BsFillCaretLeftFill.svg"
import BsFillCaretRightFill from "@/public/icons/BsillCaretRightFill.svg"

import Link from "next/link"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"

import { Trainer, getTrainers } from "@/lib/api/strapi/getTrainers"
import useInView from "@/lib/hooks/useInView"

import CoachCard from "@/components/CoachCard"
import SkewedButton from "@/components/ui/skewed-button"

import MeetYourCoachesIcon from "public/icons/meet-your-coaches.svg"

const MeetYourCoaches: FC = () => {
  const [trainers, setTrainers] = useState<Trainer[]>([])
  const [loading, setLoading] = useState(true)
  const [loadIframe, divRef] = useInView({ threshold: 0.05 })

  const fetchTrainers = useCallback(async () => {
    // const { data } = await axios.get<Trainer[]>("/api/getTrainers2")
    const data = await getTrainers()
    setTrainers(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!loadIframe) return
    fetchTrainers()
  }, [loadIframe])

  return (
    <section
      className="lg:mt-[134px] mb-[75px] lg:mb-[134px]  mx-auto px-2"
      ref={divRef}
    >
      <div className="flex flex-col items-center">
        <MeetYourCoachesIcon />
        <h2 className="mb-6 text-2xl font-bold text-center md:mb-12 md:text-3xl lg:text-4xl font-display">
          MEET YOUR COACHES
        </h2>
      </div>
      <CoachesCarousel trainers={trainers} loading={loading} />
      <div className="flex justify-center mt-4">
        <Link aria-label="See all team members" href="/all-trainers">
          <SkewedButton className="inline-block font-bold max-w-[calc(100vw-8px)] text-white transition-colors text-xs  md:text-base duration-300 ease-out hover:text-text hover:bg-brand bg-text">
            SEE ALL TEAM MEMBERS
          </SkewedButton>
        </Link>
      </div>
    </section>
  )
}

const CoachesCarousel: FC<{ trainers: Trainer[]; loading: boolean }> = ({
  trainers,
  loading,
}) => {
  const [isScrollLeftDisabled, setIsScrollLeftDisabled] = useState(true)
  const [isScrollRightDisabled, setIsScrollRightDisabled] = useState(false)
  const sliderRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const container = sliderRef.current
        setIsScrollLeftDisabled(container.scrollLeft === 0)
        setIsScrollRightDisabled(
          container.scrollLeft + container.offsetWidth >= container.scrollWidth,
        )
      }
    }

    if (sliderRef.current) {
      sliderRef.current.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (sliderRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        sliderRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [sliderRef])

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollLeft + 381,
        behavior: "smooth",
      })
    }
  }
  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollLeft - 381,
        behavior: "smooth",
      })
    }
  }

  return (
    <>
      <div
        ref={sliderRef}
        className="flex items-stretch gap-5 overflow-x-scroll x-scroll"
      >
        {loading
          ? new Array(10)
              .fill(null)
              .map((_, i) => (
                <Skeleton
                  key={i}
                  width={361}
                  height={551.22}
                  className="w-[361px]  group max-w-[calc(100vw-8px)]  rounded-xl overflow-hidden border border-gray-200 shadow-lg h-full"
                />
              ))
          : trainers.map((trainer) => {
              return (
                <div key={trainer.attributes.name}>
                  <CoachCard
                    data={{
                      name: trainer.attributes.name,
                      cover_image:
                        trainer.attributes.coverImage?.data?.attributes?.formats
                          ?.medium.url!,
                      expertise: trainer.attributes.expertise,
                      tier: trainer.attributes.tier,
                      id: trainer.id,
                    }}
                    showClassType={false}
                  />
                </div>
              )
            })}

        {new Array(2).fill(null).map((_, i) => (
          <div className="w-[360px] h-[350px]" key={i}></div>
        ))}
        {/* <ButtonGroup /> */}
      </div>
      <div className="flex justify-center gap-8 mt-6 mb-8">
        <button
          aria-label="Scroll left"
          className="p-3 text-white rounded-full disabled:bg-gray-400 bg-brand"
          onClick={slideLeft}
          disabled={isScrollLeftDisabled}
        >
          <BsFillCaretLeftFill className="w-7 h-7" />
        </button>
        <button
          aria-label="Next Slide"
          className="p-3 text-white rounded-full disabled:bg-gray-400 bg-brand"
          onClick={slideRight}
          disabled={isScrollRightDisabled}
        >
          <BsFillCaretRightFill className="w-7 h-7" />
        </button>
      </div>
    </>
  )
}

export default MeetYourCoaches
