import axios from "axios"

export interface Trainer {
  id: number
  attributes: Attributes
}

export interface Attributes {
  name: string
  expertise: string
  instagramHandle: string
  description: string
  tier: any
  createdAt: string
  updatedAt: string
  publishedAt: string
  trainerFields: string
  coverImage: CoverImage
  images: Images
  titleTag: string | null
}

export interface CoverImage {
  data: Data
}

export interface Data {
  id: number
  attributes: Attributes2
}

export interface Attributes2 {
  name: string
  alternativeText: any
  caption: any
  width: number
  height: number
  formats: Formats
  hash: string
  ext: string
  mime: string
  size: number
  url: string
  previewUrl: any
  provider: string
  provider_metadata: ProviderMetadata5
  createdAt: string
  updatedAt: string
}

export interface Formats {
  large: Large
  small: Small
  medium: Medium
  thumbnail: Thumbnail
}

export interface Large {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
  provider_metadata: ProviderMetadata
}

export interface ProviderMetadata {
  public_id: string
  resource_type: string
}

export interface Small {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
  provider_metadata: ProviderMetadata2
}

export interface ProviderMetadata2 {
  public_id: string
  resource_type: string
}

export interface Medium {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
  provider_metadata: ProviderMetadata3
}

export interface ProviderMetadata3 {
  public_id: string
  resource_type: string
}

export interface Thumbnail {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
  provider_metadata: ProviderMetadata4
}

export interface ProviderMetadata4 {
  public_id: string
  resource_type: string
}

export interface ProviderMetadata5 {
  public_id: string
  resource_type: string
}

export interface Images {
  data: Daum[]
}

export interface Daum {
  id: number
  attributes: Attributes3
}

export interface Attributes3 {
  name: string
  alternativeText: any
  caption: any
  width: number
  height: number
  formats: Formats2
  hash: string
  ext: string
  mime: string
  size: number
  url: string
  previewUrl: any
  provider: string
  provider_metadata: ProviderMetadata8
  createdAt: string
  updatedAt: string
}

export interface Formats2 {
  small: Small2
  thumbnail: Thumbnail2
}

export interface Small2 {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
  provider_metadata: ProviderMetadata6
}

export interface ProviderMetadata6 {
  public_id: string
  resource_type: string
}

export interface Thumbnail2 {
  ext: string
  url: string
  hash: string
  mime: string
  name: string
  path: any
  size: number
  width: number
  height: number
  provider_metadata: ProviderMetadata7
}

export interface ProviderMetadata7 {
  public_id: string
  resource_type: string
}

export interface ProviderMetadata8 {
  public_id: string
  resource_type: string
}

export const getTrainers = async () => {
  console.log("getTrainers")
  try {
    const { data } = await axios.get<{
      data: Trainer[]
    }>(
      `https://cms.chilimayo.com/api/trainers?populate=*`,
      // {
      //   headers: {
      //     Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
      //   },
      // }
    )
    console.log(data.data)
    return data.data
  } catch (error) {
    console.log(error)
    return []
  }
}
