import { ComponentProps, FC } from "react"

import { cn } from "@/lib/utils"

type Props = ComponentProps<"button">

const SkewedButton: FC<Props> = ({ className, children, ...rest }) => {
  return (
    <button
      aria-label="Skewed Button"
      className={cn("min-h-[40px] rounded-[10px] -skew-x-[21deg]", className)}
      {...rest}
    >
      <div className="inline-block px-[100px] pt-[20px] pb-[16px] skew-x-[21deg]">
        {children}
      </div>
    </button>
  )
}

export default SkewedButton
